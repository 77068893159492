import { request } from '@/utils/request.js'

//获取一份已评试卷
export function getOneEvaluatedTestApi (examId, achievementSummaryId) {
  return request({
    url: `/exam/score/${examId}/${achievementSummaryId}/achievementSummary/get`,
    method: 'post',
  })
}

//获取一份仲裁卷
export function getOneArbitratTestApi (examId, operationTestId) {
  return request({
    url: `/exam/score/${examId}/${operationTestId}/arbitration/get`,
    method: 'post',
  })
}

//随机抽查一份已评试卷
export function getOneRandomTestApi (examId) {
  return request({
    url: `/exam/score/${examId}/spotCheck/achievementSummary/get`,
    method: 'post',
  })
}

//仲裁|抽查评分
export function updateMarkTestApi (data) {
  return request({
    url: `/exam/score/achievementSummary/update`,
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data: data
  })
}


//下一份已评试卷(0++)
export function getNextEvaluatedTestApi (pageIndex, examId) {
  return request({
    url: `/exam/score/next/${pageIndex}/${examId}/achievementSummary/get`,
    method: 'post',
  })
}

//组长抽查驳回重新评卷
export function rejectMarkTestApi (examId, achievementSummaryId) {
  return request({
    url: `/exam/score/${examId}/${achievementSummaryId}/reject/update`,
    method: 'post',
  })
}

