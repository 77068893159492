<template>
  <div id="app">
    <!-- 视频组件 -->
    <dualVideoCom
      ref="dualVideoCom"
      :mainStreamUrl="mainStreamUrl"
      :subStreamUrl="subStreamUrl"
      :examOperationTest="examOperationTest"
      :openAI="openAI"
      @renderPointFrame="renderPointFrame"
      :showFace="showFace"
      @changeShowFace="(val)=>{
        showFace = val
      }"
    ></dualVideoCom>

    <el-row :gutter="5" id="markingVideoArbitrateTable">
      <el-col :span="12">
        <!-- ,'backgroundColor':'#dfe6ec' -->
        <el-table
          v-loading="loading"
          stripe
          border
          ref="markingVideoTable"
          :data="leftOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="index"
            label="序号"
            fixed
            show-overflow-tooltip
            width="56"
          ></el-table-column>
          <el-table-column label="评分项" show-overflow-tooltip width="540">
            <template slot-scope="scope">
              {{ scope.row.operationQuestionName }}
              <el-tooltip
                v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                effect="dark"
                :content="scope.row.markSuggest.remark"
                placement="bottom"
              >
                <i
                  class="el-icon-warning-outline"
                  style="
                    float: right;
                    line-height: 23px;
                    font-size: 18px;
                    color: #409EFF;
                  "
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="score"
            label="分值"
            show-overflow-tooltip
            width="61"
          ></el-table-column>
          <el-table-column
            prop="aiScore"
            label="AI初评"
            :width="80"
            align="center"
            v-if="openAI"
          >
            <template slot-scope="scope">{{
              scope.row.aiSupport ? scope.row.aiScore : 0
            }}</template>
          </el-table-column>
          <el-table-column
            :label="
              item.scoreType == 2
                ? item.teacherName + '(仲裁)'
                : item.scoreType == 4
                ? item.teacherName + '(异议)'
                : item.teacherName
            "
            v-for="item in examOperationTest.markTeacherRecords"
            :key="item.markRecordId"
            width="80"
          >
            <template slot-scope="scope">
              <div
                v-for="test in item.operationAchievements"
                :key="test.operationQuestionId"
              >
                <span
                  v-if="
                    test.operationQuestionId == scope.row.operationQuestionId
                  "
                  >{{ test.score }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="更改为" fixed="right" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.inputScore"
                style="max-width: 60px"
                @input="scoreInput(scope.row, scope.row.inputScore)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            v-if="openAI"
            label="评分依据"
            show-overflow-tooltip
            :width="120"
            v-once
          >
            <template slot-scope="scope">
              <div
                v-if="
                  renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                    .length > 0
                "
                class="pointFrameImg"
                style="height: 54px"
                @click="
                  jumpPointFrameListVideo(
                    renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                  )
                "
              >
                <img
                  :src="
                    renderPointFrameImg(
                      renderPointFrame(
                        scope.row.aiResultOperationQuestionItemDTO
                      )
                    )
                  "
                />
              </div>
              <div v-else style="height: 54px"></div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>

      <el-col :span="12">
        <!-- ,'backgroundColor':'#dfe6ec' -->
        <el-table
          v-loading="loading"
          stripe
          border
          ref="markingVideoTable"
          :data="rightOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="index"
            label="序号"
            fixed
            show-overflow-tooltip
            width="56"
          ></el-table-column>
          <el-table-column label="评分项" show-overflow-tooltip width="540">
            <template slot-scope="scope">
              {{ scope.row.operationQuestionName }}
              <el-tooltip
                v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                effect="dark"
                :content="scope.row.markSuggest.remark"
                placement="bottom"
              >
                <i
                  class="el-icon-warning-outline"
                  style="
                    float: right;
                    line-height: 23px;
                    font-size: 18px;
                    color: #409EFF;
                  "
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="score"
            label="分值"
            show-overflow-tooltip
            width="61"
          ></el-table-column>
          <el-table-column
            prop="aiScore"
            label="AI初评"
            :width="80"
            align="center"
            v-if="openAI"
          >
            <template slot-scope="scope">{{
              scope.row.aiSupport ? scope.row.aiScore : 0
            }}</template>
          </el-table-column>
          <el-table-column
            :label="
              item.scoreType == 2
                ? item.teacherName + '(仲裁)'
                : item.scoreType == 4
                ? item.teacherName + '(异议)'
                : item.teacherName
            "
            v-for="item in examOperationTest.markTeacherRecords"
            :key="item.markRecordId"
            width="80"
          >
            <template slot-scope="scope">
              <div
                v-for="test in item.operationAchievements"
                :key="test.operationQuestionId"
              >
                <span
                  v-if="
                    test.operationQuestionId == scope.row.operationQuestionId
                  "
                  >{{ test.score }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="更改为" fixed="right" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.inputScore"
                style="max-width: 60px"
                @input="scoreInput(scope.row, scope.row.inputScore)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            v-if="openAI"
            label="评分依据"
            show-overflow-tooltip
            :width="120"
            v-once
          >
            <template slot-scope="scope">
              <div
                v-if="
                  renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                    .length > 0
                "
                class="pointFrameImg"
                style="height: 54px"
                @click="
                  jumpPointFrameListVideo(
                    renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                  )
                "
              >
                <img
                  :src="
                    renderPointFrameImg(
                      renderPointFrame(
                        scope.row.aiResultOperationQuestionItemDTO
                      )
                    )
                  "
                />
              </div>
              <div v-else style="height: 54px"></div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-row class="marking-video-footer">
      <span v-if="examOperationTest.score" style="margin-right: 70px">
        原成绩：
        <span style="color: #409EFF">
          {{ Number(examOperationTest.score).toFixed(1) }}
        </span>
      </span>
      <span>
        考生得分：
        <span>{{ totalScore }}</span> 分
      </span>
      <div v-if="examOperationTest.scoreType == 2">
        <el-button v-throttle size="medium" type="primary" @click="submitExit"
          >提交并退出</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="submitContinue"
          >提交并继续</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="exit"
          style="margin-left: 10px"
          >仅退出</el-button
        >
      </div>
      <div v-else-if="examOperationTest.scoreType == 3">
        <el-button
          v-throttle
          v-if="urlQueryParam.text != 'evaluated'"
          size="medium"
          type="primary"
          @click="getOneRandomTest"
          >下一份</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="updateScoreMark"
          >直接修改成绩</el-button
        >
        <el-button v-throttle size="medium" type="primary" @click="reject"
          >驳回</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="exit"
          style="margin-left: 10px"
          >退出</el-button
        >
      </div>
    </el-row>
  </div>
</template>
<script>
import dualVideoCom from '@/components/dualVideoCom.vue'
import {
  getOneEvaluatedTestApi,
  getOneArbitratTestApi,
  getOneRandomTestApi,
  updateMarkTestApi,
  getNextEvaluatedTestApi,
  rejectMarkTestApi,
} from '@/api/marking/markingVideoArbitrate.js'
import { getExamineeAiMarkingApi } from '@/api/marking/ai.js'
import { durationToTimeSting, isEmpty, throttle } from '@/utils/util'
export default {
  name: 'markingVideoArbitrate',
  components: {
    dualVideoCom,
  },
  data() {
    return {
      mainStreamUrl: '',
      subStreamUrl: '',
      loading: false,

      urlQueryParam: {
        text: '',
        examId: '',
        achievementSummaryId: '',
        operationTestId: '',
        teacherId: '',
      },
      examOperationTest: {},
      aiMarkResult: {},
      frameList: [],
      operationQuestionList: [],
      leftOperationQuestionList: [],
      rightOperationQuestionList: [],
      totalScore: 0,
      nextPageIndex: 0,

      openAI: false,
      
      showFace: false
    }
  },
  mounted() {
    this.renderUrlParam()
    // this.$nextTick(() => {
    //   this.progressWidth = Math.floor(
    //     this.$refs.markingHandBoxProgressBar.clientWidth
    //   )
    // })
  },
  beforeDestroy() {
    // this.destroyPlayer()
  },
  methods: {
    renderUrlParam() {
      this.urlQueryParam.text = this.$route.query.text
      this.urlQueryParam.examId = this.$route.query.examId
      this.urlQueryParam.achievementSummaryId =
        this.$route.query.achievementSummaryId
      this.urlQueryParam.operationTestId = this.$route.query.operationTestId
      this.urlQueryParam.teacherId = this.$route.query.teacherId
      console.log(this.urlQueryParam.text)
      console.log(`examId:${this.urlQueryParam.examId},
      operationTestId:${this.urlQueryParam.operationTestId},
      achievementSummaryId:${this.urlQueryParam.achievementSummaryId}`)
      console.log(`teacherId:${this.urlQueryParam.teacherId}`)
      // this.initPlayer(this.mainStreamUrl, this.subStreamUrl)
      this.init()
    },
    init() {
      if (!isEmpty(this.urlQueryParam.text)) {
        switch (this.urlQueryParam.text) {
          case 'evaluated':
            if (
              isEmpty(this.urlQueryParam.examId) ||
              isEmpty(this.urlQueryParam.achievementSummaryId)
            ) {
              return
            }
            //获取一份已评试卷
            this.getOneEvaluatedTest()
            break
          case 'arbitrate':
            if (
              isEmpty(this.urlQueryParam.examId) ||
              isEmpty(this.urlQueryParam.operationTestId)
            ) {
              return
            }
            //获取一份仲裁卷
            this.getOneArbitratTest()
            break
          case 'random':
            if (isEmpty(this.urlQueryParam.examId)) {
              return
            }
            //随机抽查一份已评试卷
            this.getOneRandomTest()
            break
        }
      }
    },
    //获取一份已评试卷
    getOneEvaluatedTest() {
      getOneEvaluatedTestApi(
        this.urlQueryParam.examId,
        this.urlQueryParam.achievementSummaryId
      )
        .then((res) => {
          if (res.success) {
            let dataList = null
            if (res.data.aiSupport) {
              this.openAI = true

              // dataList = await this.getAiMark(this.examId, res.data.examineeId)
            }
            this.renderData(res.data, dataList)
            // this.getExamineeVideo(res.data.examineeId)
            // 调组件
            this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    //获取一份仲裁卷
    getOneArbitratTest() {
      getOneArbitratTestApi(
        this.urlQueryParam.examId,
        this.urlQueryParam.operationTestId
      )
        .then((res) => {
          if (res.success) {
            this.renderData(res.data)
            // this.getExamineeVideo(res.data.examineeId)
            // 调组件
            this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
          } else {
            let msg = ''
            switch (res.code) {
              case '506':
                msg = '没有仲裁卷了'
                break
              case '829':
                msg = res.msg
                break
            }
            this.msgWarning(msg)
            this.$router.push({ name: 'markingDetails' })
          }
        })
        .catch(() => {})
    },
    //随机抽查一份已评试卷
    getOneRandomTest: throttle(function () {
      getOneRandomTestApi(this.urlQueryParam.examId)
        .then((res) => {
          if (res.success) {
            this.renderData(res.data)
            // this.getExamineeVideo(res.data.examineeId)
            // 调组件
            this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
          } else {
            let msg = ''
            switch (res.code) {
              case '506':
                msg = '没有未抽查的试卷'
                break
            }
            this.$confirm(msg, '系统提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {})
              .catch(() => {})
          }
        })
        .catch(() => {})
    }, 3000),
    getExamineeAiMarking() {
      getExamineeAiMarkingApi(
        this.examOperationTest.examId,
        this.examOperationTest.examineeId
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.aiMarkResult = {}
            this.aiMarkResult = res.data
            this.$refs.dualVideoCom.aiMarkResult = this.aiMarkResult
            this.totalScore = 0
            // 获取AI初评的分数
            if (
              this.aiMarkResult.aiResultOperationQuestionItemDTOList &&
              this.aiMarkResult.aiResultOperationQuestionItemDTOList.length > 0
            ) {
              for (
                let i = 0;
                i < this.examOperationTest.operationQuestions.length;
                i++
              ) {
                let optionItem = this.examOperationTest.operationQuestions[i]
                let item =
                  this.aiMarkResult.aiResultOperationQuestionItemDTOList[i]
                optionItem.aiSupport = true
                this.$set(
                  optionItem,
                  'aiOperationItemName',
                  item.aiOperationItemName
                )
                this.$set(optionItem, 'inputScore', item.aiScore)
                this.$set(optionItem, 'aiScore', item.aiScore)
                if (item.aiScore) {
                  this.totalScore += item.aiScore
                }
                optionItem.aiResultOperationQuestionItemDTO = item
                let currentFrameList = this.renderPointFrame(item)
                if (currentFrameList.length > 0) {
                  this.frameList = this.frameList.concat(currentFrameList)
                  this.$refs.dualVideoCom.frameList = this.frameList
                }
              }
              this.frameList.sort(this.frameListSort)
              this.$refs.dualVideoCom.frameList = this.frameList
              let nextFrameStart = 0
              for (
                let i = this.examOperationTest.operationQuestions.length - 1;
                i >= 0;
                i--
              ) {
                let operationQuestion =
                  this.examOperationTest.operationQuestions[i]
                let currentFrameList = this.renderPointFrame(
                  operationQuestion.aiResultOperationQuestionItemDTO
                )
                if (currentFrameList.length > 0) {
                  currentFrameList.sort(this.frameListSort)
                  let firstFrame = currentFrameList[0]
                  if (firstFrame.frameTime) {
                    this.$set(
                      operationQuestion,
                      'frameStartTime',
                      firstFrame.frameTime
                    )
                    this.$set(operationQuestion, 'frameEndTime', nextFrameStart)
                    this.$set(operationQuestion, 'frameFirst', firstFrame)
                    nextFrameStart = operationQuestion.frameStartTime
                  }
                }
              }
            }
          } else {
            if (res.code != '500') {
              this.msgWarning('未获取到考生的AI评分成绩')
            } else {
              this.msgError('获取成绩明细信息失败')
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = true
        })
    },
    renderData(data, dataList = null) {
      this.examOperationTest = {}
      this.examOperationTest = data
      if (this.examOperationTest.examName.length > 25) {
        this.examOperationTest.aliasExamName =
          this.examOperationTest.examName.substring(0, 25) + '...'
      } else {
        this.examOperationTest.aliasExamName = this.examOperationTest.examName
      }
      this.examOperationTest.examNameLen =
        this.examOperationTest.examName.length
      this.mainStreamUrl = this.$imageAddress(
        this.examOperationTest.topCameraStream0
      )
      if (!isEmpty(this.examOperationTest.frontCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      } else if (!isEmpty(this.examOperationTest.sideCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.sideCameraStream0
        )
      } else {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      }
      this.$nextTick(() => {
        // this.startPlayer(this.mainStreamUrl, this.subStreamUrl)
        this.$refs.dualVideoCom.initPlayer(
          this.mainStreamUrl,
          this.subStreamUrl
        )
        // // 调组件
        // this.$refs.dualVideoCom.startPlayer(
        //   this.mainStreamUrl,
        //   this.subStreamUrl
        // )
      })
      this.totalScore = 0
      this.operationQuestionList = []
      this.leftOperationQuestionList = []
      this.rightOperationQuestionList = []
      let listSize = this.examOperationTest.operationQuestions.length
      let splitPoint = Math.ceil(listSize / 2)
      for (let i = 0; i < listSize; i++) {
        let item = this.examOperationTest.operationQuestions[i]
        item.aiSupport = false
        item.aiScore = 0
        item.aiResultOperationQuestionItemDTO = []
      }
      this.examOperationTest.operationQuestions.forEach((item, index) => {
        item.index = index + 1
        this.examOperationTest.markTeacherRecords.forEach((teacher) => {
          if (
            teacher.markRecordId == this.examOperationTest.checkMarkRecordId
          ) {
            if (teacher.scoreType == 3) {
              teacher.operationAchievements.forEach((test) => {
                if (test.operationQuestionId == item.operationQuestionId) {
                  this.$set(item, 'inputScore', test.score)
                }
              })
            } else {
              this.$set(item, 'inputScore', '')
            }
          }
        })
        this.operationQuestionList.push(item)
        if (!isEmpty(item.inputScore)) {
          this.totalScore += item.inputScore
        }
        if (index < splitPoint) {
          this.leftOperationQuestionList.push(item)
        } else {
          this.rightOperationQuestionList.push(item)
        }
        if (this.openAI) {
          this.getExamineeAiMarking()
        } else {
          this.loading = false
        }
      })
      this.totalScore = this.totalScore.toFixed(1)
    },

    download() {},
    fullMarking(row) {
      this.$set(row, 'inputScore', row.score)
      this.computeScore(row)
    },
    zeroMarking(row) {
      this.$set(row, 'inputScore', 0)
      this.computeScore(row)
    },
    scoreInput(row, val) {
      if (isEmpty(val)) {
        this.$set(row, 'inputScore', '')
        this.computeScore()
        return
      }
      val = val.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')
      if (val > row.score) {
        val = row.score
      }
      this.$set(row, 'inputScore', val)
      this.computeScore()
    },
    computeScore() {
      let totalScore = 0
      this.operationQuestionList.forEach((item) => {
        if (isEmpty(item)) {
          return
        }
        if (isEmpty(item.inputScore)) {
          return
        }
        totalScore += Number(item.inputScore)
      })
      this.totalScore = totalScore.toFixed(1)
    },
    checkScore(txt) {
      let queryParam = this.getScoreMarkParam()
      try {
        queryParam.operationQuestions.forEach((item, index) => {
          if (isEmpty(item.score) || isNaN(item.score)) {
            throw new Error(`第${index + 1}行分数不能为空`)
          }
        })
        switch (txt) {
          case 'exit':
            this.updateMarkTest()
            break
          case 'continue':
            this.updateScoreMarkContinue()
            break
        }
      } catch (e) {
        this.msgWarning(e.message)
      }
    },
    //提交并退出
    submitExit() {
      this.updateScoreMark()
    },
    updateScoreMark() {
      this.checkScore('exit')
    },
    updateMarkTest() {
      let queryParam = this.getScoreMarkParam()
      let loading = this.globalLoading('正在提交...')
      //仲裁|抽查评分
      updateMarkTestApi(JSON.parse(JSON.stringify(queryParam)))
        .then((res) => {
          loading.close()
          if (res.success) {
            if (res.data) {
              this.msgSuccess('提交成功')
              this.showFace = false
              this.$router.push({ name: 'markingDetails' })
            }
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    //提交并继续
    submitContinue: throttle(function () {
      this.checkScore('continue')
    }, 3000),
    updateScoreMarkContinue() {
      let queryParam = this.getScoreMarkParam()
      updateMarkTestApi(JSON.stringify(queryParam))
        .then((res) => {
          this.$refs.dualVideoCom.shutDownIsPicture()
          if (res.success) {
            if (res.data) {
              this.msgSuccess('提交成功')
              this.showFace = false
              this.getOneArbitratTest()
            }
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    getScoreMarkParam() {
      let operationQuestions = []
      this.operationQuestionList.forEach((item) => {
        let operationQuestion = {
          operationQuestionId: 0,
          operationQuestionName: '',
          score: '',
          sortNum: 0,
        }
        operationQuestion.operationQuestionId = item.operationQuestionId
        operationQuestion.operationQuestionName = item.operationQuestionName
        if (!isEmpty(item.inputScore)) {
          operationQuestion.score = Number(item.inputScore)
        }
        operationQuestion.sortNum = item.sortNum
        operationQuestions.push(operationQuestion)
      })
      let queryParam = {
        channel: 0,
        examId: this.examOperationTest.examId,
        examineeId: this.examOperationTest.examineeId,
        operationQuestions: operationQuestions,
        operationTestId: this.examOperationTest.operationTestId,
        review: this.examOperationTest.review,
        scoreType: this.examOperationTest.scoreType,
      }
      return queryParam
    },
    //获取下一份已评试卷
    getNextMarkTest: throttle(function () {
      this.getNextEvaluatedTest()
      this.nextPageIndex++
    }, 3000),
    getNextEvaluatedTest() {
      getNextEvaluatedTestApi(
        this.nextPageIndex,
        this.examOperationTest.examId
      ).then((res) => {
        if (res.success) {
          this.renderData(res.data)
          // this.getExamineeVideo(res.data.examineeId)
          // 调组件
          this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
        } else {
          let msg = ''
          switch (res.code) {
            case '506':
              msg = '已经是最后一份了'
              break
          }
          this.$confirm(msg, '系统提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.$router.push({ name: 'markingDetails' })
            })
            .catch(() => {})
        }
      })
    },
    // 驳回
    reject() {
      this.globalMessageBox(
        `若驳回该份试卷，则之前对该份试卷的全部评分操作将无效，
      试卷将会重新放入评卷池，随机分配给评卷老师重新评卷。
      确定驳回吗？`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          this.rejectMarkTest()
        })
        .catch(() => {})
    },
    //组长抽查驳回重新评卷
    rejectMarkTest() {
      if (this.urlQueryParam.text == 'random') {
        this.urlQueryParam.achievementSummaryId =
          this.examOperationTest.achievementSummaryId
      }
      if (
        isEmpty(
          this.urlQueryParam.examId ||
            isEmpty(this.urlQueryParam.achievementSummaryId)
        )
      ) {
        return
      }
      rejectMarkTestApi(
        this.urlQueryParam.examId,
        this.urlQueryParam.achievementSummaryId
      ).then((res) => {
        if (res.success) {
          this.msgSuccess('驳回成功')
          this.$router.push({ name: 'markingDetails' })
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    //仅退出
    exit() {
      this.globalMessageBox(
        `退出后不保留评卷记录，
      确定退出吗？`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          this.$router.push({ name: 'markingDetails' })
        })
        .catch(() => {})
    },
    jumpPointFrameListVideo(aiResultOperationQuestionItemDTOList) {
      if (aiResultOperationQuestionItemDTOList.length > 0) {
        this.$refs.dualVideoCom.currentDuration = Math.floor(
          aiResultOperationQuestionItemDTOList[0].frameTime / 1000
        )
        this.$refs.dualVideoCom.customSwitchProgress()
      }
    },
    renderPointFrame(aiResultOperationQuestionItemDTO) {
      let list = aiResultOperationQuestionItemDTO.aiResultItemPointDTOList
      if (list && list.length > 0) {
        for (let index = 0; index < list.length; index++) {
          let eventList = list[index]?.aiResultItemPointEventDTOList
          let aiEventList = eventList?.[0]?.aiResultItemPointEventAiEventDTOList
          let frameList =
            aiEventList?.[0]?.aiResultItemPointEventAiEventFrameDTOList
          if (frameList && frameList.length > 0) {
            frameList.forEach((item) => {
              item.displayContent = list[0].aiPointContent
            })
            return frameList
          }
        }
      }
      return []
    },
    renderPointFrameImg(aiResultItemPointEventAiEventFrameDTOList) {
      if (aiResultItemPointEventAiEventFrameDTOList.length > 0) {
        return this.$imageAddress(
          aiResultItemPointEventAiEventFrameDTOList[0].frameUrl
        )
      } else {
        return ''
      }
    },
    frameListSort(frameFirst, frameSecond) {
      if (frameFirst.frameTime - frameSecond.frameTime < 0) {
        return -1
      } else if (frameFirst.frameTime - frameSecond.frameTime > 0) {
        return 1
      } else {
        return 0
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#app {
  padding: 0 5px;
  margin-top: 2px;
}

.marking-video-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marking-video-footer > span {
  color: #303133;
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
}

.marking-video-footer > span > span {
  color: #409EFF;
}

::v-deep .el-message-box > .el-message-box__btns > {
  text-align: center;
}

::v-deep .el-input__inner {
  height: 32px;
  text-align: center;
}

::v-deep ::-webkit-scrollbar {
  width: 1px;
  height: 8px;
}

::v-deep ::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

::v-deep ::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  transition: background-color 0.3s;
  cursor: pointer;
}

.marking-hand-box-progress-time {
  width: 150px;
  margin-right: 25px;
}

#markingVideoArbitrateTable ::v-deep .el-table .el-table__cell {
  padding: 8px 0;
}

.pointFrameImg {
  width: 100%;
  height: 100%;
  border: 1px solid #cbd8f5;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
